<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Edit {{ role.name }}</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <a @click="submitForm" class="header-icon header-icon-2" style="cursor: pointer;">
        <i class="fas fa-check font-18 text-success"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <!-- <div class="card card-style rounded-s"> -->
    <div class="content mb-3">
        <Form novalidate :validation-schema="formValidations" v-slot="{ errors }" autocomplete="off">


            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.role }">
                <i class="fa fa-user"></i>
                <Field as="input" type="text" name="role" v-model="updateRole" :value="role.name"
                    class="form-control" id="role" placeholder="Role">
                </Field>
                <label for="role" class="color-primary-dark font-13 font-600 rounded-m">Update Role</label>
                <em>(required)</em>
                <template v-slot:error>
                    {{errors.role}}
                </template>
            </form-control>

            <!-- PARTNER PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 letterSpacing">Partner Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_read" value="true"
                                v-model="role.permissions.partner_read" class="form-check-input" id="partner_read">
                            </Field>
                            <label for="partner_read" class="form-check-label letterSpacing">Partner List/view </label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_create" value="true"
                                v-model="role.permissions.partner_create" class="form-check-input" id="partner_create">
                            </Field>
                            <label for="partner_create" class="form-check-label letterSpacing">Partner Create </label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_create}}</template>
                    </form-control>
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_change_approve_reject_status}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_change_approve_reject_status" value="true"
                                v-model="role.permissions.partner_change_approve_reject_status" class="form-check-input"
                                id="partner_change_approve_reject_status"></Field>
                            <label for="partner_change_approve_reject_status"
                                class="form-check-label letterSpacing">Partner
                                Approve/Reject</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_change_approve_reject_status}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_change_commission_payment_status}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_change_commission_payment_status"
                                value="true" v-model="role.permissions.partner_change_commission_payment_status"
                                class="form-check-input" id="partner_change_commission_payment_status"></Field>
                            <label for="partner_change_commission_payment_status"
                                class="form-check-label letterSpacing">Change partner commission/payment status</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_change_commission_payment_status}}</template>
                    </form-control>
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_update_personal_detail}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_update_personal_detail" value="true"
                                v-model="role.permissions.partner_update_personal_detail" class="form-check-input"
                                id="partner_update_personal_detail"></Field>
                            <label for="partner_update_personal_detail" class="form-check-label letterSpacing">Partner
                                update Personal detail</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_update_personal_detail}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_update_business_detail}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_update_business_detail" value="true"
                                v-model="role.permissions.partner_update_business_detail" class="form-check-input"
                                id="partner_update_business_detail"></Field>
                            <label for="partner_update_business_detail" class="form-check-label letterSpacing">Partner
                                update Business detail</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_update_business_detail}}</template>
                    </form-control>
                </div>
            </div>
            <!-- PARTNER PERMISSION END -->


            <!-- PARTNER TYPE PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Partner Type Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_type_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_type_read" value="true"
                                v-model="role.permissions.partner_type_read" class="form-check-input"
                                id="partner_type_read">
                            </Field>
                            <label for="partner_type_read" class="form-check-label letterSpacing">Partner Type
                                List/View</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_type_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_type_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_type_create" value="true"
                                v-model="role.permissions.partner_type_create" class="form-check-input"
                                id="partner_type_create"></Field>
                            <label for="partner_type_create" class="form-check-label letterSpacing">Partner Type
                                Create</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_type_create}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_type_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_type_update" value="true"
                                v-model="role.permissions.partner_type_update" class="form-check-input"
                                id="partner_type_update"></Field>
                            <label for="partner_type_update" class="form-check-label letterSpacing">Partner Type Update
                            </label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_type_update}}</template>
                    </form-control>
                </div>
            </div>
            <!-- PARTNER TYPE PERMISSION END -->


            <!-- PARTNER ADMIN PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Partner Admin Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_admin_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_admin_read" value="true"
                                v-model="role.permissions.partner_admin_read" class="form-check-input"
                                id="partner_admin_read">
                            </Field>
                            <label for="partner_admin_read" class="form-check-label letterSpacing">Partner Admin
                                List/View</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_admin_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_admin_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_admin_create" value="true"
                                v-model="role.permissions.partner_admin_create" class="form-check-input"
                                id="partner_admin_create"></Field>
                            <label for="partner_admin_create" class="form-check-label letterSpacing">Partner Admin
                                Create</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_admin_create}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.partner_admin_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="partner_admin_update" value="true"
                                v-model="role.permissions.partner_admin_update" class="form-check-input"
                                id="partner_admin_update"></Field>
                            <label for="partner_admin_update" class="form-check-label letterSpacing">Partner Admin
                                Update
                            </label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.partner_admin_update}}</template>
                    </form-control>
                </div>
            </div>
            <!-- PARTNER ADMIN PERMISSION END -->


            <!-- CLUSTER ADMIN PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Cluster Admin Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.cluster_admin_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="cluster_admin_read" value="true"
                                v-model="role.permissions.cluster_admin_read" class="form-check-input"
                                id="cluster_admin_read">
                            </Field>
                            <label for="cluster_admin_read" class="form-check-label letterSpacing">Cluster Admin
                                List/View</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.cluster_admin_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.cluster_admin_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="cluster_admin_create" value="true"
                                v-model="role.permissions.cluster_admin_create" class="form-check-input"
                                id="cluster_admin_create"></Field>
                            <label for="cluster_admin_create" class="form-check-label letterSpacing">Cluster Admin
                                Create</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.cluster_admin_create}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.cluster_admin_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="cluster_admin_update" value="true"
                                v-model="role.permissions.cluster_admin_update" class="form-check-input"
                                id="cluster_admin_update"></Field>
                            <label for="cluster_admin_update" class="form-check-label letterSpacing">Cluster Admin
                                Update
                            </label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.cluster_admin_update}}</template>
                    </form-control>
                </div>
            </div>
            <!-- CLUSTER ADMIN PERMISSION END -->


            <!-- CUSTOMER PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Customer Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.customer_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="customer_read" value="true"
                                v-model="role.permissions.customer_read" class="form-check-input" id="customer_read">
                            </Field>
                            <label for="customer_read" class="form-check-label letterSpacing">Customer List/view</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.customer_read}}</template>
                    </form-control>
                </div>
            </div>
            <!-- CUSTOMER PERMISSION END -->


            <!-- ORDER PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Order Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.order_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="order_read" value="true"
                                v-model="role.permissions.order_read" class="form-check-input" id="order_read"></Field>
                            <label for="order_read" class="form-check-label letterSpacing">Order List/View</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.order_read}}</template>
                    </form-control>
                </div>
            </div>
            <!-- ORDER PERMISSION END -->


            <!-- COMMISSION PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Commission Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.commission_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="commission_read" value="true"
                                v-model="role.permissions.commission_read" class="form-check-input"
                                id="commission_read">
                            </Field>
                            <label for="commission_read" class="form-check-label letterSpacing">Commission
                                List/View</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.commission_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.commission_disable}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="commission_disable" value="true"
                                v-model="role.permissions.commission_disable" class="form-check-input"
                                id="commission_disable">
                            </Field>
                            <label for="commission_disable" class="form-check-label letterSpacing">Commission
                                disable</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.commission_disable}}</template>
                    </form-control>
                </div>
            </div>
            <!-- COMMISSION PERMISSION END -->


            <!-- PAYMENT PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Payment Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.payment_transaction_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="payment_transaction_read" value="true"
                                v-model="role.permissions.payment_transaction_read" class="form-check-input"
                                id="payment_transaction_read"></Field>
                            <label for="payment_transaction_read" class="form-check-label letterSpacing">Payment
                                Transaction
                                List/View</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.payment_transaction_read}}</template>
                    </form-control>
                </div>
            </div>
            <!-- PAYMENT PERMISSION END -->


            <!-- ROLE PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Role Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.role_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="role_read" value="true"
                                v-model="role.permissions.role_read" class="form-check-input" id="role_read"></Field>
                            <label for="role_read" class="form-check-label letterSpacing">Role List/View</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.role_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.role_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="role_create" value="true"
                                v-model="role.permissions.role_create" class="form-check-input" id="role_create">
                            </Field>
                            <label for="role_create" class="form-check-label letterSpacing">Role Create</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.role_create}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.role_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="role_update" value="true"
                                v-model="role.permissions.role_update" class="form-check-input" id="role_update">
                            </Field>
                            <label for="role_update" class="form-check-label letterSpacing">Role Update</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.role_update}}</template>
                    </form-control>
                </div>
            </div>
            <!-- ROLE PERMISSION END -->


            <!-- PASSBOOK PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Passboook Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.passbook_statement_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="passbook_statement_read" value="true"
                                v-model="role.permissions.passbook_statement_read" class="form-check-input"
                                id="passbook_statement_read"></Field>
                            <label for="passbook_statement_read" class="form-check-label letterSpacing">Passbook
                                User/Statement List</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.passbook_statement_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.passbook_bonus_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="passbook_bonus_create" value="true"
                                v-model="role.permissions.passbook_bonus_create" class="form-check-input"
                                id="passbook_bonus_create"></Field>
                            <label for="passbook_bonus_create" class="form-check-label letterSpacing">Passbook Bonus
                                Create</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.passbook_bonus_create}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.passbook_penalty_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="passbook_penalty_create" value="true"
                                v-model="role.permissions.passbook_penalty_create" class="form-check-input"
                                id="passbook_penalty_create"></Field>
                            <label for="passbook_penalty_create" class="form-check-label letterSpacing">Passbook Penalty
                                Update</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.passbook_penalty_create}}</template>
                    </form-control>
                </div>
            </div>
            <!-- PASSBOOK PERMISSION END -->


            <!-- REWARD POINT PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Reward Point Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.reward_statement_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="reward_statement_read" value="true"
                                v-model="role.permissions.reward_read" class="form-check-input"
                                id="reward_statement_read">
                            </Field>
                            <label for="reward_statement_read" class="form-check-label letterSpacing">Reward Statement
                                List</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.reward_statement_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.reward_point_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="reward_point_create" value="true"
                                v-model="role.permissions.reward_create" class="form-check-input"
                                id="reward_point_create">
                            </Field>
                            <label for="reward_point_create" class="form-check-label letterSpacing">Reward Point
                                Create</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.reward_point_create}}</template>
                    </form-control>
                </div>
            </div>
            <!-- REWARD POINT PERMISSION END -->


            <!-- TICKET PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Ticket Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.ticket_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="ticket_read" value="true"
                                v-model="role.permissions.ticket_read" class="form-check-input" id="ticket_read">
                            </Field>
                            <label for="ticket_read" class="form-check-label letterSpacing">Ticket List/View</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.ticket_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.ticket_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="ticket_create" value="true"
                                v-model="role.permissions.ticket_create" class="form-check-input" id="ticket_create">
                            </Field>
                            <label for="ticket_create" class="form-check-label letterSpacing">Ticket Create</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.ticket_create}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.ticket_comment_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="ticket_comment_create" value="true"
                                v-model="role.permissions.ticket_comment_create" class="form-check-input"
                                id="ticket_comment_create"></Field>
                            <label for="ticket_comment_create" class="form-check-label letterSpacing">Ticket Comment
                                Create</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.ticket_comment_create}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.ticket_payment_request_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="ticket_payment_request_update" value="true"
                                v-model="role.permissions.ticket_payment_request_update" class="form-check-input"
                                id="ticket_payment_request_update"></Field>
                            <label for="ticket_payment_request_update" class="form-check-label letterSpacing">Ticket
                                Payment
                                Request Approve/Update</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.ticket_payment_request_update}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.ticket_kyc_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="ticket_kyc_update" value="true"
                                v-model="role.permissions.ticket_kyc_update" class="form-check-input"
                                id="ticket_kyc_update">
                            </Field>
                            <label for="ticket_kyc_update" class="form-check-label letterSpacing">Ticket KYC
                                Approve/update</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.ticket_kyc_update}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.ticket_issue_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="ticket_issue_update" value="true"
                                v-model="role.permissions.ticket_issue_update" class="form-check-input"
                                id="ticket_issue_update"></Field>
                            <label for="ticket_issue_update" class="form-check-label letterSpacing">Ticket Issue
                                Update</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.ticket_issue_update}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.ticket_bank_detail_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="ticket_bank_detail_update" value="true"
                                v-model="role.permissions.ticket_bank_detail_update" class="form-check-input"
                                id="ticket_bank_detail_update"></Field>
                            <label for="ticket_bank_detail_update" class="form-check-label letterSpacing">Ticket Bank
                                detail
                                Approve/Update</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.ticket_bank_detail_update}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.ticket_profile_detail_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="ticket_profile_detail_update" value="true"
                                v-model="role.permissions.ticket_profile_detail_update" class="form-check-input"
                                id="ticket_profile_detail_update"></Field>
                            <label for="ticket_profile_detail_update" class="form-check-label letterSpacing">Ticket
                                Profile
                                Detail Approve/Update</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.ticket_profile_detail_update}}</template>
                    </form-control>
                </div>
            </div>
            <!-- TICKET PERMISSION END -->


            <!-- CUSTOM ATTRIBUTE PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Custom Attribute Permission</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.custom_attribute_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="custom_attribute_read" value="true"
                                v-model="role.permissions.custom_attribute_read" class="form-check-input"
                                id="custom_attribute_read"></Field>
                            <label for="custom_attribute_read" class="form-check-label letterSpacing">Attribute
                                List/View</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.custom_attribute_read}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.custom_attribute_create}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="custom_attribute_create" value="true"
                                v-model="role.permissions.custom_attribute_create" class="form-check-input"
                                id="custom_attribute_create"></Field>
                            <label for="custom_attribute_create" class="form-check-label letterSpacing">Attribute
                                Create</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.custom_attribute_create}}</template>
                    </form-control>

                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.custom_attribute_update}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="custom_attribute_update" value="true"
                                v-model="role.permissions.custom_attribute_update" class="form-check-input"
                                id="custom_attribute_update"></Field>
                            <label for="custom_attribute_update" class="form-check-label letterSpacing">Attribute
                                Update</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.custom_attribute_update}}</template>
                    </form-control>
                </div>
            </div>
            <!-- CUSTOM ATTRIBUTE PERMISSION END -->


            <!-- ANALYTICS PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Analytics</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.referral_analytics_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="referral_analytics_read" value="true"
                                v-model="role.permissions.referral_analytics_read" class="form-check-input"
                                id="referral_analytics_read"></Field>
                            <label for="referral_analytics_read" class="form-check-label letterSpacing">Referarl
                                Analytics</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.referral_analytics_read}}</template>
                    </form-control>
                </div>
            </div>
            <!-- ANALYTICS PERMISSION END -->
            
            <!-- OUTLETS PERMISSION START -->
            <h3 class="col-12 mb-2 font-300 letterSpacing ms-4 mt-4">Outlet</h3>
            <div class="card card-style rounded-s">
                <div class="content mb-3">
                    <form-control class="validate-field" inputType="checkbox"
                        :class="{'is-invalid' : errors.outlet_read}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="outlet_read" value="true"
                                v-model="role.permissions.outlet_read" class="form-check-input" id="outlet_read">
                            </Field>
                            <label for="outlet_read" class="form-check-label letterSpacing">Outlet List</label>
                            <i class="icon-check-1 fa fa-square color-gray-light font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 text-success"></i>
                        </div>
                        <template v-slot:error>{{errors.outlet_read }}</template>
                    </form-control>
                </div>
            </div>
            <!-- OUTLETS PERMISSION END -->

        </Form>
    </div>
    <!-- </div> -->
</div>
<app-footer />